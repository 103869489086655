import { PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from 'libs/shared/models';
import { createSlice } from 'store/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors';
import { authSaga } from './auth.saga';
import {
  initialState,
  AuthState,
  LoginApiArgsProps,
  UserProps,
  userLoginStatus,
  SessionApiResponseProps,
} from '../models';

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    postLogin(state: AuthState, action: PayloadAction<LoginApiArgsProps>) {
      state.fetchStatus = FetchStatus.Loading;
      state.errorMessage = '';
    },
    postSuccessLogin(state: AuthState, action: PayloadAction<UserProps>) {
      state.user = action.payload;
      state.userStatus = userLoginStatus.LoggedIn;
      state.fetchStatus = FetchStatus.Success;
    },
    postErrorLogin(state: AuthState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
      state.fetchStatus = FetchStatus.Error;
    },
    loadSessionDetails(state: AuthState) {
      state.fetchStatus = FetchStatus.Loading;
    },
    loadedSessionDetails(
      state: AuthState,
      action: PayloadAction<SessionApiResponseProps>,
    ) {
      state.fetchStatus = FetchStatus.Success;
      state.user = action.payload.user;
      state.userStatus = action.payload.userStatus;
    },
    loadedErrorSessionDetails(state: AuthState) {
      state.fetchStatus = FetchStatus.Success;
      state.userStatus = userLoginStatus.LoggedOut;
    },
    postLogout(state: AuthState) {
      state.userStatus = null;
      state.user = null;
    },
    postSuccessLogout(state: AuthState) {
      state.userStatus = userLoginStatus.LoggedOut;
      state.fetchStatus = FetchStatus.Success;
    },
  },
});
export const { actions: authActions, reducer } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  return { actions: slice.actions };
};
