import { Outlet } from 'react-router-dom';
import { FeatureHeader, FeatureAdminNav } from 'admin';
import styles from './admin-layout.module.scss';

export const AdminLayout = () => {
  return (
    <div>
      <FeatureHeader />
      <div className={styles['outlet-container']}>
        <FeatureAdminNav />
        <section>
          <div className={styles['main-container']}>
            <Outlet />
          </div>
        </section>
      </div>
    </div>
  );
};
