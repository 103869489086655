import React from 'react';
import styles from './text-field.module.scss';
import { Label } from '../label';
import { TextError } from '../text-error';

export interface TextFieldProps {
  placeholder: string;
  label?: string;
  error?: boolean;
  errorMessage?: string;
  value: string;
  onChange?: (event: string, keyName: string) => void;
  iconName?: string;
  onIconClick?: () => void;
  disabled?: boolean;
  required?: boolean;
  type?: React.HTMLInputTypeAttribute;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  multiple?: boolean;
  keyName?: string;
  hideError?: boolean;
}

export const TextField = ({
  placeholder,
  label = '',
  error = false,
  errorMessage = 'Please Enter the field',
  value,
  onChange = () => {},
  iconName,
  onIconClick = () => {},
  disabled = false,
  type = 'text',
  Icon,
  required,
  multiple = false,
  keyName = '',
  hideError = false,
}: TextFieldProps) => {
  const style: React.CSSProperties = {};
  if (!multiple) {
    style['height'] = '40px';
  }

  return (
    <div className={styles['container']} data-hide={`${hideError}`}>
      {label && (
        <Label>
          {label}
          {required && <span className={styles['required-style']}>*</span>}
        </Label>
      )}
      <section
        className={styles['input-container']}
        style={style}
        data-disabled={`${disabled}`}>
        {multiple ? (
          <textarea
            placeholder={placeholder}
            value={value}
            onChange={(event) => onChange(event.target.value, keyName)}
            disabled={disabled}
            rows={10}
          />
        ) : (
          <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={(event) => onChange(event.target.value, keyName)}
            disabled={disabled}
          />
        )}
        {!!iconName && (
          <i className="material-icons" onClick={onIconClick}>
            {iconName}
          </i>
        )}
        {!!Icon && <Icon onClick={onIconClick} />}
      </section>
      {!hideError && <TextError>{error && errorMessage}</TextError>}
    </div>
  );
};
