import styles from './chip.module.scss';

export interface ChipProps {
  label: string;
  className?: 'active' | 'disabled';
}

export const Chip = ({ label, className = 'active' }: ChipProps) => {
  return (
    <div className={`${styles['chip']} ${styles[`chip-${className}`]}`}>
      {label}
    </div>
  );
};
