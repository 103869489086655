import { lazyLoad } from 'utils/loadable';

export const FeatureBusinessList = lazyLoad(
  () => import('./feature-business-list'),
  (module) => module.FeatureBusinessList,
);

export const FeatureBusinessLocation = lazyLoad(
  () => import('../feature-business-list/feature-business-location'),
  (module) => module.FeatureBusinessLocation,
);
