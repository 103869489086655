import { useSelector } from 'react-redux';
import { selectUser } from 'admin/auth/slice/auth.selector';
import { userRole } from '../models';

export const useUserDetails = () => {
  const user = useSelector(selectUser);

  return {
    user,
    isAgent: user?.role === userRole.agent,
    isAdmin: user?.role === userRole.admin,
  };
};
