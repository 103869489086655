import React from 'react';
import styles from './text-error.module.scss';
export interface TextErrorProps {
  children: React.ReactNode;
  className?: 'label-style' | 'error-style';
}

export const TextError: React.FC<TextErrorProps> = ({
  children,
  className = 'label-style',
}) => {
  return <p className={styles[className]}>{children}</p>;
};
