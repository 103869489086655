import styles from './image-upload.module.scss';

export interface ImageUploadProps {
  disabled: boolean;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  multiple: boolean;
  fileType?: string;
}

export const ImageUpload = ({
  disabled,
  onUpload,
  multiple,
  fileType,
}: ImageUploadProps) => {
  return (
    <div className={styles['file-area']} data-disabled={`${disabled}`}>
      <input
        type="file"
        value={''}
        onChange={onUpload}
        multiple={multiple}
        disabled={disabled}
        accept={fileType}
      />
      <section>
        <i className="material-icons">add</i>
      </section>
    </div>
  );
};
