import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import styles from './pagination.module.scss';
import { Button } from '../';
const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  gap: '10px',
});
export interface PaginationProps {
  count: number;
  onChange: (page: number) => void;
  defaultPage: number;
}
export const Pagination = ({
  count,
  defaultPage,
  onChange,
}: PaginationProps) => {
  const { items } = usePagination({
    count,
    defaultPage,
    onChange: (event, page) => {
      onChange(page);
    },
  });
  return (
    <div className={styles['container']}>
      <List>
        {items.map(({ page, type, selected, onClick, ...item }, index) => {
          let children = null;
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <Button
                type={selected ? 'primary-round' : 'secondary-round'}
                onClick={(event: any) => {
                  onClick(event);
                }}>
                {page}
              </Button>
            );
          } else {
            children = (
              <Button
                disabled={item.disabled}
                onClick={(event) => {
                  onClick(event);
                }}
                type={'secondary-round'}>
                {type}
              </Button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </div>
  );
};
