/* eslint-disable @typescript-eslint/no-unused-vars */
import { PayloadAction } from '@reduxjs/toolkit';
import { setAuthorizationToken } from 'api/api-service';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  LoginApiResponseProps,
  LoginApiArgsProps,
  userLoginStatus,
} from '../models';
import { postLoginApi, fetchSessionDetailsApi, postLogout } from '../services';
import { authActions as actions } from './auth.slice';

export function* submitLogin(action: PayloadAction<LoginApiArgsProps>) {
  try {
    const response: LoginApiResponseProps = yield call(
      postLoginApi,
      action.payload,
    );
    if (response.status && response.data) {
      setAuthorizationToken(response.token);
      localStorage.setItem('authToken', response.token);
      yield put(actions.postSuccessLogin(response.data));
    } else {
      yield put(actions.postErrorLogin('Something Went Wrong'));
    }
  } catch (error: any) {
    yield put(
      actions.postErrorLogin(
        error?.response?.data?.message || 'Something Went Wrong',
      ),
    );
  }
}
export function* submitLogout() {
  try {
    setAuthorizationToken('');
    yield localStorage.removeItem('authToken');
    const response: LoginApiResponseProps = yield call(postLogout);
    yield put(actions.postSuccessLogout());
  } catch (error) {
    setAuthorizationToken('');
    yield localStorage.removeItem('authToken');
    yield put(actions.postSuccessLogout());
  }
}
export function* getSessionDetails() {
  try {
    const authtoken = localStorage.getItem('authToken');
    setAuthorizationToken(authtoken || '');
    const response: LoginApiResponseProps = yield call(fetchSessionDetailsApi);
    if (response.status) {
      yield put(
        actions.loadedSessionDetails({
          user: response.data,
          userStatus: response.data
            ? userLoginStatus.LoggedIn
            : userLoginStatus.LoggedOut,
        }),
      );
    } else {
      yield put(actions.postLogout());
    }
  } catch (error) {
    yield put(actions.postLogout());
  }
}

export function* authSaga() {
  yield takeLatest(actions.postLogin.type, submitLogin);
  yield takeLatest(actions.loadSessionDetails.type, getSessionDetails);
  yield takeLatest(actions.postLogout.type, submitLogout);
}
