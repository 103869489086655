import CircularProgress from '@mui/material/CircularProgress';
import styles from './spinner.module.scss';
export interface SpinnerProps {
  className?: string;
}

export const Spinner = ({ className = 'spinner-container' }) => {
  return (
    <div className={styles[className]}>
      <CircularProgress size={60} />
    </div>
  );
};
