import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthSlice } from 'admin/auth/slice/auth.slice';
import { selectUserStatus } from 'admin/auth/slice/auth.selector';

export const useAuthHook = () => {
  const dispatch = useDispatch();
  const actions = useAuthSlice().actions;
  const userStatus = useSelector(selectUserStatus);

  useEffect(() => {
    if (!userStatus) {
      dispatch(actions.loadSessionDetails());
    }
  }, [actions, dispatch, userStatus]);

  return { userStatus };
};
