import * as React from 'react';
import MaterialSwitch from '@mui/material/Switch';
export interface SwitchProps {
  checked: boolean;
  onChange: () => void;
}

export function Switch({ checked, onChange }: SwitchProps) {
  return (
    <MaterialSwitch
      checked={checked}
      onChange={onChange}
      color="secondary"
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
}
