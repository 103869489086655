import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Spinner } from 'libs/shared/core';
import { userLoginStatus } from 'admin/auth/models';
import { ADMIN, ADMIN_DASHBOARD } from 'libs/shared/constants';
import { useAuthHook } from 'admin/shared';

export const LoginAuth = () => {
  const { userStatus } = useAuthHook();

  if (!userStatus) {
    return <Spinner />;
  }
  if (userStatus === userLoginStatus.LoggedIn) {
    return <Navigate to={`/${ADMIN}/${ADMIN_DASHBOARD}`} replace={true} />;
  }

  return <Outlet />;
};
