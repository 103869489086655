export const ADMIN = 'admin';
export const ADMIN_DASHBOARD = 'dashboard';
export const ADMIN_BUSINESS = 'business';
export const ADMIN_USER_GROUP = 'user-group';
export const ADMIN_ENQUIRY = 'enquiry';

export const SUB_CATEGORY = 'sub-category';
export const BUSINESS_LIST = 'business-list';
export const BUSINESS = 'business';
export const BUSINESS_BY_LOCATION = 'business-by-location';
