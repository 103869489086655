import { Button, TextField } from 'libs/shared/core';
import styles from './page-header.module.scss';

export interface PageHeaderProps {
  title: string;
  iconName: string;
  buttonName?: string;
  buttonClick?: () => void;
  search?: string;
  onSearch?: (value: string) => void;
}

export const PageHeader = ({
  title,
  iconName,
  buttonName,
  buttonClick,
  search,
  onSearch,
}: PageHeaderProps) => {
  return (
    <div className={styles['header-container']}>
      <section className={styles['title-container']}>
        <i className="material-icons">{iconName}</i>
        <h2>{title}</h2>
        {onSearch && (
          <TextField
            placeholder={'Search'}
            value={search || ''}
            hideError
            onChange={(event) => {
              onSearch(event);
            }}
          />
        )}
      </section>
      <div>
        {buttonName && buttonClick && (
          <Button onClick={buttonClick} type={'primary'} iconName="add">
            {buttonName}
          </Button>
        )}
      </div>
    </div>
  );
};
