import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { FeatureHeader, FeatureSideNav, FeatureFooter } from 'user';
import styles from './user-layout.module.scss';

export const UserLayout = () => {
  const [sideNav, setSideNav] = useState(false);
  const closeSideNav = () => setSideNav((prev) => !prev);
  return (
    <div className={styles['layout-container']}>
      <FeatureSideNav sideNavOpen={sideNav} close={closeSideNav} />
      <div className={styles['main-container']}>
        <FeatureHeader onHamburgerClick={closeSideNav} />
        <div className={styles['content-container']}>
          <Outlet />
        </div>
        <FeatureFooter />
      </div>
    </div>
  );
};
