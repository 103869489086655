import { Route, Routes } from 'react-router-dom';
import {
  AuthPage,
  AdminDashboardPage,
  AdminUserGroupPage,
  AdminBusinessPage,
} from 'admin';
import {
  FeatureHome,
  FeatureSubCategory,
  FeatureBusinessList,
  FeatureBusiness,
  FeatureBusinessLocation,
} from 'user';
import {
  ADMIN,
  ADMIN_DASHBOARD,
  ADMIN_USER_GROUP,
  ADMIN_BUSINESS,
  SUB_CATEGORY,
  BUSINESS_LIST,
  BUSINESS,
  BUSINESS_BY_LOCATION,
  ADMIN_ENQUIRY,
} from 'libs/shared/constants';
import { AdminLayout, UserLayout } from 'libs/shared/layout';
import { SecureAuth } from './secureAuth';
import { LoginAuth } from './loginAuth';
import { useUserDetails } from 'admin/shared/custome-hooks/useUserDetails';
import { EnquiryPage } from 'admin/feature-enquiry';

function App() {
  const { isAdmin } = useUserDetails();
  return (
    <Routes>
      <Route path="/" element={<UserLayout />}>
        <Route path="/" element={<FeatureHome />} />
        <Route path={SUB_CATEGORY} element={<FeatureSubCategory />} />
        <Route path={BUSINESS_LIST} element={<FeatureBusinessList />} />
        <Route path={BUSINESS} element={<FeatureBusiness />} />
        <Route
          path={BUSINESS_BY_LOCATION}
          element={<FeatureBusinessLocation />}
        />
      </Route>
      <Route path={ADMIN} element={<LoginAuth />}>
        <Route index element={<AuthPage />} />
      </Route>
      <Route path="/" element={<SecureAuth />}>
        <Route path={ADMIN} element={<AdminLayout />}>
          <Route path={ADMIN_DASHBOARD} element={<AdminDashboardPage />} />
          <Route path={ADMIN_BUSINESS} element={<AdminBusinessPage />} />
          <Route
            path={`${ADMIN_BUSINESS}/:id`}
            element={<AdminBusinessPage />}
          />
          <Route path={`${ADMIN_ENQUIRY}`} element={<EnquiryPage />} />
          {isAdmin && (
            <Route path={ADMIN_USER_GROUP} element={<AdminUserGroupPage />} />
          )}
          <Route path="*" element={<div>Not Found</div>} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
