import { FetchStatus } from 'libs/shared/models';
import { UserProps, userLoginStatus } from './auth.model';

export interface AuthState {
  user: UserProps | null;
  userStatus: userLoginStatus | null;
  fetchStatus: FetchStatus;
  errorMessage: string;
}

export const initialState: AuthState = {
  user: null,
  userStatus: null,
  fetchStatus: FetchStatus.Initial,
  errorMessage: '',
};
