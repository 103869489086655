import { Dialog, DialogProps } from '@mui/material';
import styles from './modal.module.scss';

export interface ModalProps {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  fullWidth?: boolean;
  maxWidth?: DialogProps['maxWidth'];
  fullScreen?: boolean;
}

export const Modal = ({
  open,
  handleClose,
  children,
  fullWidth = true,
  maxWidth = 'md',
  fullScreen = false,
}: ModalProps) => {
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className={styles['modal-container']}>{children}</div>
    </Dialog>
  );
};
