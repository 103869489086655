import React, { useMemo, useState } from 'react';
import styles from './select.module.scss';
import { Label } from '../label';
import { TextError } from '../text-error';
import { ClickAwayListener } from '@mui/material';

export interface OptionsProps {
  [key: string]: string;
}

export interface SelectProps {
  placeholder: string;
  label: string;
  error?: boolean;
  errorMessage?: string;
  value: OptionsProps | string | Array<OptionsProps | string>;
  onChange?: (value: string | OptionsProps) => void;
  iconName?: string;
  onIconClick?: () => void;
  disabled?: boolean;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  options: OptionsProps[] | string[];
  itemKey?: keyof OptionsProps;
  itemValue?: keyof OptionsProps;
  required?: boolean;
  returnObject?: boolean;
  multiSelect?: boolean;
}

export const Select = ({
  placeholder,
  label,
  error = false,
  errorMessage = 'Please Select the Value',
  value,
  onChange = () => {},
  iconName,
  onIconClick = () => {},
  disabled = false,
  options,
  itemKey,
  itemValue,
  Icon,
  required,
  returnObject,
  multiSelect = false,
}: SelectProps) => {
  const { displayValue = '', displayKey = '' } = useMemo(() => {
    let selectedOption: OptionsProps | string | Array<OptionsProps | string> =
      value;
    if (returnObject) {
      selectedOption = value;
      if (multiSelect) {
        const displaykeys: string[] = [];
        const displayValue: string[] = [];
        (value as Array<OptionsProps>).forEach((item: OptionsProps) => {
          if (itemKey) displaykeys.push(item[itemKey]);
          if (itemValue) displayValue.push(item[itemValue]);
        });
        selectedOption = {
          [itemKey as string]: displaykeys.join(','),
          [itemValue as string]: displayValue.join(', '),
        };
      }
    } else {
      options.forEach((item: OptionsProps | string) => {
        if (!multiSelect && !Array.isArray(value)) {
          const displayKey: string = itemKey
            ? (item as OptionsProps)[itemKey]
            : (item as string);

          if (value === displayKey) {
            selectedOption = item;
          }
        } else {
          const displaykeys: string[] = [];
          const displayValues: string[] = [];
          (value as Array<OptionsProps | string>).forEach(
            (item: OptionsProps | string) => {
              if (itemKey && itemValue) {
                const data = item as OptionsProps;
                displaykeys.push(data[itemKey]);
                displayValues.push(data[itemValue]);
              } else {
                displayValues.push(item as string);
              }
            },
          );
          if (itemKey && itemValue) {
            selectedOption = {
              [itemKey as string]: displaykeys.join(','),
              [itemValue as string]: displayValues.join(', '),
            };
          } else {
            selectedOption = displayValues.join(',');
          }
        }
      });
    }
    if (itemKey && itemValue)
      return {
        displayKey: (selectedOption as OptionsProps)?.[itemKey] || '',
        displayValue: (selectedOption as OptionsProps)?.[itemValue] || '',
      };
    else {
      return {
        displayKey: (selectedOption as string) || '',
        displayValue: (selectedOption as string) || '',
      };
    }
  }, [itemKey, itemValue, multiSelect, options, returnObject, value]);
  const [open, setOpen] = useState(false);
  return (
    <div className={styles['container']}>
      <Label>
        {label}
        {required && <span className={styles['required-style']}>*</span>}
      </Label>
      <ClickAwayListener onClickAway={() => open && setOpen(false)}>
        <div className={styles['select-container']}>
          <section
            className={styles['input-container']}
            onClick={() => {
              setOpen((prev) => !prev);
            }}
            data-disabled={`${disabled}`}>
            <input
              type={'text'}
              placeholder={placeholder}
              value={displayValue}
              disabled
            />
            <i className="material-icons">arrow_drop_down</i>
          </section>
          {open && (
            <ul className={styles['list']}>
              {options.map((item: OptionsProps | string) => {
                const value: string = itemValue
                  ? (item as OptionsProps)[itemValue]
                  : (item as string);
                const key: string = itemKey
                  ? (item as OptionsProps)[itemKey]
                  : (item as string);
                if (multiSelect) {
                }
                const selected = displayKey.split(',').includes(key);
                const className = selected ? '-selected' : '';
                return (
                  <li
                    key={key}
                    className={styles['list-item' + className]}
                    onClick={() => {
                      const selectedOption = returnObject ? item : key;
                      onChange(selectedOption);
                      if (!multiSelect) setOpen(false);
                    }}>
                    {value}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </ClickAwayListener>

      <TextError>{error && errorMessage}</TextError>
    </div>
  );
};
