import React from 'react';
import styles from './table.module.scss';
import { TableProps } from '../../models';
import { Spinner } from '../spinner';

export const Table = <T extends { _id: string }, K extends keyof T>({
  header = [],
  data = [],
  loading,
}: TableProps<T, K>) => {
  return (
    <div className={styles['main-container']}>
      <table className={styles['table-container']}>
        <thead>
          <tr className={styles['table-header']}>
            {header.map((header) => {
              const style = {
                opacity: 1,
                '--row-align': header?.align || 'left',
              };
              if (!header.hideColumn)
                return (
                  <th key={`${header?.field as string}`} style={style}>
                    {header.label}
                  </th>
                );
              return (
                <th
                  key={`${header?.field as string}`}
                  style={{ display: 'none' }}></th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((list: T, index) => {
            return (
              <tr className={styles['table-row']} key={list['_id']}>
                {header.map((header, index) => {
                  const style = {
                    opacity: 1,
                    '--row-align': header?.align || 'left',
                  };
                  const label: string =
                    (list?.[header?.field as K] as string) || '';
                  if (!header.hideColumn)
                    return (
                      <td key={`column-${index}`} style={style}>
                        {header.Component ? (
                          <header.Component
                            data={list}
                            accessLabel={header.field}
                          />
                        ) : (
                          label || '----'
                        )}
                      </td>
                    );
                  return (
                    <th
                      key={`column-${index}`}
                      style={{ display: 'none' }}></th>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 && (
        <div className={styles['table-no-data-row']}>
          <p className={styles['no-data-style']}>No Data to Display</p>
        </div>
      )}
      {loading && <Spinner />}
    </div>
  );
};
