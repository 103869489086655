import ApiService from 'api/api-service';
import { LoginApiArgsProps, LoginApiResponseProps } from '../models';

export const postLoginApi = async (args: LoginApiArgsProps) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/admin/login`;
  const response = await ApiService.post(url, args);
  return response.data;
};

export const fetchSessionDetailsApi = async () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/admin/session/me`;
  const response = await ApiService.get(url);
  return response.data;
};
export const postLogout = async (): Promise<LoginApiResponseProps> => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}/admin/logout`;
  const response = await ApiService.post(url);
  return response.data;
};
