import { useState } from 'react';
import { Label } from '../label';
import { TextError } from '../text-error';
import { OptionsProps } from '..';
import styles from './auto-suggestion.module.scss';
import { ClickAwayListener } from '@mui/material';

export interface AutoSuggestionProps {
  placeholder: string;
  label?: string;
  error?: boolean;
  errorMessage: string;
  value: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  iconName?: string;
  onIconClick?: () => void;
  disabled?: boolean;
  required?: boolean;
  type?: React.HTMLInputTypeAttribute;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  options: OptionsProps[] | string[];
  itemKey?: keyof OptionsProps;
  itemValue?: keyof OptionsProps;
  returnObject?: boolean;
  hideError?: boolean;
  onSelect?: (value: string | OptionsProps) => void;
  className?: string;
}

export const AutoSuggestion = ({
  placeholder,
  label = '',
  error = false,
  errorMessage,
  value,
  onChange = () => {},
  iconName,
  onIconClick = () => {},
  disabled = false,
  type = 'text',
  Icon,
  required,
  options,
  itemKey,
  itemValue,
  returnObject,
  onSelect,
  className = '',
  hideError = false,
}: AutoSuggestionProps) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles['container']}>
      <Label>
        {label}
        {required && <span className={styles['required-style']}>*</span>}
      </Label>
      <ClickAwayListener onClickAway={() => open && setOpen(false)}>
        <div className={styles['options-container']}>
          <section
            className={
              styles[className ? 'custome-input-container' : 'input-container']
            }
            data-disabled={`${disabled}`}>
            <input
              type={type}
              placeholder={placeholder}
              value={value}
              onChange={(event) => {
                onChange(event);
                if (!open) setOpen(true);
              }}
              disabled={disabled}
              onFocus={() => {
                setOpen(true);
              }}
              className={styles[className]}
            />
            {!!iconName && (
              <i className="material-icons" onClick={onIconClick}>
                {iconName}
              </i>
            )}
            {!!Icon && <Icon onClick={onIconClick} />}
          </section>
          {open && value.length > 4 && (
            <ul className={styles['list']}>
              {options.map((item: OptionsProps | string) => {
                const value: string = itemValue
                  ? (item as OptionsProps)[itemValue]
                  : (item as string);
                const key: string = itemValue
                  ? (item as OptionsProps)[itemValue]
                  : (item as string);
                return (
                  <li
                    key={key}
                    className={styles['list-item']}
                    onClick={() => {
                      const selectedOption = returnObject ? item : key;
                      onSelect?.(selectedOption);
                      setOpen(false);
                    }}>
                    {value}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </ClickAwayListener>
      {!hideError && <TextError>{error && errorMessage}</TextError>}
    </div>
  );
};
