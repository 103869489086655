export interface fromProps {
  label: string;
  placeholder?: string;
  value: string;
  required?: boolean;
  validator?: (value: string) => void;
  error: boolean;
  errorMessage: string;
  iconName?: string;
  onIconClick?: () => void;
  type?: React.HTMLInputTypeAttribute;
}

export interface userForm {
  [key: string]: fromProps;
}

export interface LoginApiArgsProps {
  email: string;
  password: string;
}

export interface LoginApiResponseProps {
  status: boolean;
  data: UserProps | null;
  token: string;
}

export interface UserProps {
  _id: string;
  name: string;
  email: string;
  role: string;
  active: boolean;
}

export enum userLoginStatus {
  LoggedIn = 'LoggedIn',
  LoggedOut = 'LoggedOut',
}

export interface SessionApiResponseProps {
  user: UserProps | null;
  userStatus: userLoginStatus;
}
