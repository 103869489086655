import React from 'react';
import styles from './label.module.scss';

export interface LabelProps {
  children: React.ReactNode;
  className?: 'label' | 'header';
}

export const Label: React.FC<LabelProps> = ({
  children,
  className = 'label',
}) => {
  return <p className={styles[`${className}-style`]}>{children}</p>;
};
