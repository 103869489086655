import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export const ApiService = axios.create({
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
  },
});
let authToken = '';

export const setAuthorizationToken = (token: string) => {
  authToken = token;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};
const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};
const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers = { ...config.headers };
  if (!config?.headers?.Authorization && authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  config.headers.withCredentials = true;
  return config;
};

ApiService.interceptors.response.use(onResponse, onResponseError);
ApiService.interceptors.request.use(onRequest, onRequestError);

export default ApiService;
