import styles from './button.module.scss';

export interface ButtonProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  margin?: string;
  onClick: (event: any) => void;
  type?:
    | 'primary'
    | 'secondary'
    | 'primary-round'
    | 'secondary-round'
    | 'tertiary';
  iconName?: string;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  fullWidth = false,
  Icon,
  iconName,
  margin = '0',
  onClick,
  type,
  disabled = false,
}) => {
  const style = { outline: 'none', '--btn-margin': margin };
  return (
    <button
      disabled={disabled}
      className={`${styles['button']} ${
        fullWidth && styles['button-width-full']
      } ${type && styles[`btn-${type}`]}`}
      style={style}
      onClick={onClick}>
      {iconName && <i className="material-icons">{iconName}</i>}
      {children}
      {Icon && <Icon />}
    </button>
  );
};
