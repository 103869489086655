import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'root-state';
import { initialState } from '../models';

const selectDomain = (state: RootState) => state?.auth || initialState;

export const selectUser = createSelector([selectDomain], (auth) => auth.user);

export const selectUserStatus = createSelector(
  [selectDomain],
  (auth) => auth.userStatus,
);
export const selectFetchStatus = createSelector(
  [selectDomain],
  (auth) => auth.fetchStatus,
);
export const selectErrorMessage = createSelector(
  [selectDomain],
  (auth) => auth.errorMessage,
);
